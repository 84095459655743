// require jQuery normally
const $ = require('jquery');
const moment = require('moment');

// create global $ and jQuery variables
global.$ = global.jQuery = $;
global.moment = moment;

require('bootstrap/js/dist/index');
require('bootstrap/js/dist/util');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/dropdown');
require('bootstrap/js/dist/scrollspy');
require('bootstrap/js/dist/carousel');
require('bootstrap/js/dist/button');
require('bootstrap/js/dist/modal');
require('bootstrap/js/dist/popover');
require('bootstrap/js/dist/scrollspy');
require('bootstrap/js/dist/tab');
require('bootstrap/js/dist/tooltip');

$(document).ready(function () {
    var onoff = $(".on-off");
    // Paragraphe-On-Off
    onoff.addClass("off");
    $(".off").nextUntil('.on-off, hr').hide();

    onoff.each(function () {
        $(this).click(function () {
            if ($(this).hasClass("off")) {
                $(this).removeClass("off").addClass("on");
                onoff.not(this).removeClass("on").addClass("off");
            } else {
                $(this).removeClass("on").addClass("off");
            }

            $(".off").nextUntil('.on-off, hr').hide("slow");
            $(".on").nextUntil('.on-off, hr').show("slow");
        });
    });
});
